import React, { Suspense, lazy, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

// Components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import LoadingSpinner from "./utils/LoadingSpinner";
import TermsModal from "./components/TermsModal";
import PrivacyModal from "./components/PrivacyModal";

// Services and Solutions data
import {
  ComplianceData,
  GRCData,
  PCIDSSData,
  ISO27001Data,
  GDPRData,
  vCISOData,
  SecurityArchitectureData,
  SIEMData,
  VAPTData,
  SupportMaintenanceData,
} from "./data/ServicesData";

import {
  NetworkInfraData,
  SecuritySolutionsData,
  DataCenterSolutionsData,
  VirtualizationData,
  CloudServicesData,
} from "./data/SolutionsData";
// Lazy load the pages
const HomePage = lazy(() => import("./pages/HomePage"));
const AboutPage = lazy(() => import("./pages/AboutPage"));
const ServicePage = lazy(() => import("./pages/ServicePage"));
const SolutionPage = lazy(() => import("./pages/SolutionPage"));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  const [loading, setLoading] = useState(true);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  //function to make the website lazy load
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Delay in milliseconds

    return () => clearTimeout(timer);
  }, []);

  //functions to open the terms & conditions and privacy policy dialogs
  const handleTermsOpenModal = () => {
    setIsTermsModalOpen(true);
  };
  const handlePrivacyOpenModal = () => {
    setIsPrivacyModalOpen(true);
  };

  //functions to close the terms & conditions and privacy policy dialogs
  const handleTermsCloseModal = () => {
    setIsTermsModalOpen(false);
  };
  const handlePrivacyCloseModal = () => {
    setIsPrivacyModalOpen(false);
  };

  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      {loading ? (
        <div className="flex w-full h-screen justify-center items-center">
          <LoadingSpinner />
        </div>
      ) : (
        <Suspense
          fallback={
            <div className="flex w-full h-screen justify-center items-center">
              <LoadingSpinner />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />

            {/* Service pages */}
            <Route
              path="/complianceandregulartoryconsulting"
              element={<ServicePage Data={ComplianceData} />}
            />
            <Route
              path="/governanceriskandcompliance"
              element={<ServicePage Data={GRCData} />}
            />
            <Route path="/pcidss" element={<ServicePage Data={PCIDSSData} />} />
            <Route
              path="/iso27001consulting"
              element={<ServicePage Data={ISO27001Data} />}
            />
            <Route
              path="/gdprcompliance"
              element={<ServicePage Data={GDPRData} />}
            />
            <Route path="/vciso" element={<ServicePage Data={vCISOData} />} />
            <Route
              path="/securityarchitecturereveiwassessment"
              element={<ServicePage Data={SecurityArchitectureData} />}
            />
            <Route path="/siem" element={<ServicePage Data={SIEMData} />} />
            <Route path="/vapt" element={<ServicePage Data={VAPTData} />} />
            <Route
              path="/supportmaintenance"
              element={<ServicePage Data={SupportMaintenanceData} />}
            />

            {/* Solution pages */}
            <Route
              path="/networkinfrastructuresolutions"
              element={<SolutionPage Data={NetworkInfraData} />}
            />
            <Route
              path="/securitysolutions"
              element={<SolutionPage Data={SecuritySolutionsData} />}
            />
            <Route
              path="/datacentersolutions"
              element={<SolutionPage Data={DataCenterSolutionsData} />}
            />
            <Route
              path="/virtualizationsolutions"
              element={<SolutionPage Data={VirtualizationData} />}
            />
            <Route
              path="/cloudsolutions"
              element={<SolutionPage Data={CloudServicesData} />}
            />
          </Routes>
        </Suspense>
      )}
      <Footer
        onTermsOpenModal={handleTermsOpenModal}
        onPrivacyOpenModal={handlePrivacyOpenModal}
      />
      <TermsModal show={isTermsModalOpen} onClose={handleTermsCloseModal} />
      <PrivacyModal
        show={isPrivacyModalOpen}
        onClose={handlePrivacyCloseModal}
      />
    </Router>
  );
}

export default App;
