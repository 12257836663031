//network infrastructure imports
import NetworkBg from "../assets/solution_images/network_infra/hero_net_infra.jpg";
import NetworkImage from "../assets/solution_images/network_infra/network_infra.jpg";

//security solutions import
import SecurityBg from "../assets/solution_images/security_sol/hero_image.jpg";
import SecurityImage from "../assets/solution_images/security_sol/WhatIs.jpg";

//data center imports
import DatacenterBg from "../assets/solution_images/data_center/hero_image.jpg";
import DatacenterImage from "../assets/solution_images/data_center/WhatIs.jpg";

//Virtualization Solutions imports
import VirBg from "../assets/solution_images/virtualization/hero_image.jpg";
import VirImage from "../assets/solution_images/virtualization/WhatIs.jpg";

//cloud imports
import CloudBg from "../assets/solution_images/cloud/hero_image.jpg";
import CloudImage from "../assets/solution_images/cloud/WhatIs.jpg";

export const NetworkInfraData = {
  HeroImage: NetworkBg,
  HeroHeading: "Network Infrastructure",
  HeroSubheading:
    "Secure Your Network, Secure Your Future: Protect Against Cyber Threats with Robust Network Infrastructure.",
  CatchyHeading:
    "The Backbone of Modern Business: In today's digital age, a reliable and efficient network infrastructure is crucial for seamless business operations and sustained growth.",
  CatchySubheading:
    "We provide comprehensive network infrastructure solutions that ensure your business operates smoothly, stays connected, and remains secure.",
  WhatIsImage: NetworkImage,
  Question: "What is network infrastructure",
  Answer:
    "Network infrastructure forms the backbone of modern business operations, ensuring reliable connectivity, seamless communication, and robust security. It encompasses everything from hardware and software to network resources and services, enabling efficient data flow and protecting against cyber threats. Investing in a strong network infrastructure is essential for optimizing performance, supporting growth, and safeguarding sensitive information.",
  Solutions: [
    {
      heading: "Network Design and Implementation",
      desc1:
        "Offers: Customized network architecture tailored to your business needs.",
      desc2:
        "What it is: Planning and setting up a reliable and efficient network from scratch.",
    },
    {
      heading: "Network Security Solutions",
      desc1:
        "Offers: Advanced protection against cyber threats and unauthorized access.",
      desc2:
        "What it is: Implementing security measures like firewalls, intrusion detection systems, and encryption",
    },
    {
      heading: "Wireless Network Solutions",
      desc1:
        "Offers: Reliable and secure wireless connectivity for your business.",
      desc2:
        "What it is: Setting up and managing Wi-Fi networks to ensure robust wireless communication.",
    },
    {
      heading: "Data Center Networking",
      desc1: "Offers: Efficient and secure data center connectivity.",
      desc2:
        "What it is: Managing the network within a data center for optimal performance and reliability.",
    },
    {
      heading: "Network Performance Optimization",
      desc1: "Offers: Enhanced network speed and efficiency.",
      desc2:
        "What it is: Analyzing and improving network performance to eliminate bottlenecks.",
    },
    {
      heading: "Network Monitoring and Management",
      desc1: "Offers: Proactive monitoring and maintenance of your network.",
      desc2:
        "What it is: Continuous surveillance and administration to prevent downtime and resolve issues swiftly.",
    },
    {
      heading: "Disaster Recovery and Business Continuity Planning",
      desc1: "Offers: Strategies to maintain operations during disruptions.",
      desc2:
        "What it is: Developing and implementing plans to ensure business resilience and quick recovery.",
    },
    {
      heading: "VPN and Remote Access Solutions",
      desc1: "Offers: Secure access to your network from any location.",
      desc2:
        "What it is: Setting up Virtual Private Networks to enable remote work securely.",
    },
  ],
};

export const SecuritySolutionsData = {
  HeroImage: SecurityBg,
  HeroHeading: "Managed IT Security and Compliance",
  HeroSubheading:
    "Secure Your Network with Custom-Configured IT Security Solutions.",
  CatchyHeading:
    "Tailored Protection: Ensuring the Security of Sophisticated Networks in a Dynamic Business Environment.",
  CatchySubheading:
    "Our IT security services are designed to protect your network comprehensively, ensuring cost-effectiveness, flexibility, and agility.",
  WhatIsImage: SecurityImage,
  Question: "What are Managed IT Security and Compliance Services?",
  Answer:
    "Our diverse products and services are custom-configured to meet your IT security and compliance needs, ensuring every aspect of your network is secure. We design solutions for sophisticated networks, including banking and financial institutions, while maintaining quality and reducing costs. Our expertise addresses complex security needs from disruptive technologies like big data, cloud, social, and mobile. With global experience and partnerships with top information security specialists, we offer next-gen information security and compliance programs to enhance cost-effectiveness, flexibility, and agility in a rapidly changing business environment.",
  Solutions: [
    {
      heading: "Advanced Persistent Threat (APT) Protection",
      desc1: "Offers: Defense against long-term, targeted cyber threats.",
      desc2:
        "What it is: Utilizing sophisticated tools to detect and mitigate persistent cyber attacks aimed at stealing data or compromising operations.",
    },
    {
      heading: "Application Security",
      desc1:
        "Offers: Comprehensive protection for web and mobile applications.",
      desc2:
        "What it is: Implementing security measures throughout the software development lifecycle to protect applications from vulnerabilities.",
    },
    {
      heading: "Behavioral Analytics",
      desc1: "Offers: Detection of unusual user behavior.",
      desc2:
        "What it is: Monitoring and analyzing user behavior patterns to identify potential security threats from within the organization.",
    },
    {
      heading: "Deception Technology",
      desc1: "Offers: Proactive threat detection through deception.",
      desc2:
        "What it is: Deploying decoys and traps to lure attackers and detect malicious activities early.",
    },
    {
      heading: "Micro-Segmentation",
      desc1: "Offers: Enhanced network security through segmentation.",
      desc2:
        "What it is: Dividing networks into smaller, isolated segments to limit the spread of cyber threats and protect sensitive data.",
    },
    {
      heading: "Physical Security Integration",
      desc1:
        "Offers: Coordination between physical and cybersecurity measures.",
      desc2:
        "What it is: Ensuring physical security systems like surveillance cameras and access controls are integrated with cybersecurity protocols.",
    },
    {
      heading: "Security Orchestration, Automation, and Response (SOAR)",
      desc1: "Offers: Streamlined security operations and incident response.",
      desc2:
        "What it is: Automating security workflows and response actions to improve efficiency and reduce response times.",
    },
    {
      heading: "Threat Hunting",
      desc1: "Offers: Proactive search for cyber threats within the network.",
      desc2:
        "What it is: Actively searching for and mitigating potential threats that have evaded traditional security measures.",
    },
    {
      heading: "Virtual Security Operations Center (vSOC)",
      desc1: "Offers: Remote monitoring and management of security operations.",
      desc2:
        "What it is: Providing 24/7 security oversight and incident response through a virtual SOC.",
    },
    {
      heading: "Web Security Solutions",
      desc1: "Offers: Protection for web-based assets and transactions.",
      desc2:
        "What it is: Implementing security measures to safeguard websites and web applications from cyber threats.",
    },
  ],
};

export const DataCenterSolutionsData = {
  HeroImage: DatacenterBg,
  HeroHeading: "Data Center Solutions",
  HeroSubheading:
    "Build and Secure Your Data Center: The Heart of Your Business Operations.",
  CatchyHeading:
    "The Backbone of Modern Business: A robust data center is crucial for seamless business operations and data management.",
  CatchySubheading:
    "We provide comprehensive data center solutions, ensuring your critical IT infrastructure is secure, reliable, and efficiently managed.",
  WhatIsImage: DatacenterImage,
  Question: "What is a Data Center?",
  Answer:
    "A data center is vital for storing, managing, and retrieving invaluable data. IonArc Consulting Services builds top-tier data centers with robust plans and strategies to ensure security and efficiency. We help design purpose-built facilities for critical IT infrastructure, including servers, storage, and networking equipment. Our data centers feature redundant power supplies, environmental controls, and fire suppression. We also offer advice on co-location facilities, where you can purchase rack space, cabinets, or cages in established data centers.",
  Solutions: [
    {
      heading: "Data Center Design and Implementation",
      desc1: "Offers: Customized design and setup of data center facilities.",
      desc2:
        "What it is: Planning and building a robust data center tailored to your business needs, including infrastructure layout and resource allocation.",
    },
    {
      heading: "Power and Environmental Control",
      desc1: "Offers: Reliable power supply and environmental management.",
      desc2:
        "What it is: Implementing backup power systems and environmental controls to ensure consistent operation and protection of IT equipment.",
    },
    {
      heading: "Fire Suppression Systems",
      desc1: "Offers: Advanced fire detection and suppression solutions.",
      desc2:
        "What it is: Installing fire suppression systems to protect data center assets from fire hazards and ensure safety.",
    },
    {
      heading: "Co-Location Advisory Services",
      desc1: "Offers: Guidance on utilizing co-location facilities.",
      desc2:
        "What it is: Advising on co-location options for purchasing rack space, full or half cabinets, or full cages in established data centers.",
    },
    {
      heading: "Data Center Security Solutions",
      desc1: "Offers: Comprehensive security measures for data centers.",
      desc2:
        "What it is: Implementing physical and cybersecurity protocols to protect data center infrastructure from threats.",
    },
    {
      heading: "Data Center Monitoring and Management",
      desc1:
        "Offers: Continuous surveillance and maintenance of data center operations.",
      desc2:
        "What it is: Providing ongoing monitoring and management services to ensure optimal performance and quick issue resolution.",
    },
  ],
};

export const VirtualizationData = {
  HeroImage: VirBg,
  HeroHeading: "Virtualization Solutions",
  HeroSubheading:
    "Transform Your IT Landscape with Advanced Virtualization Solutions.",
  CatchyHeading:
    "Revolutionize Computing: Harness the Power of Virtualization to Optimize Resources and Enhance Efficiency.",
  CatchySubheading:
    "We provide comprehensive virtualization solutions that enable you to run multiple virtual machines on a single physical machine, sharing resources and maximizing efficiency.",
  WhatIsImage: VirImage,
  Question: "What is Virtualization?",
  Answer: `Virtualization is a technique in computer science that creates virtual machine environments, simulating underlying hardware to run any software, including operating systems. It transforms IT by allowing multiple virtual machines to run on a single physical machine, optimizing resource use. IonArc Consulting Services offers comprehensive virtualization solutions for desktops, servers, storage, and networks. Our certified professionals design, implement, and support these solutions to build automated data centers, reducing costs and increasing efficiency. We specialize in dynamic virtualization, moving beyond server consolidation to automate entire IT infrastructures, enhancing capacity management, service levels, and IT processes. We term this approach the "virtual infrastructure."`,
  Solutions: [
    {
      heading: "Desktop Virtualization",
      desc1:
        "Offers: Enhanced management and deployment of desktop environments.",
      desc2:
        "What it is: Implementing virtual desktops to improve user experience and simplify desktop management.",
    },
    {
      heading: "Server Virtualization",
      desc1: "Offers: Efficient server management and utilization.",
      desc2:
        "What it is: Consolidating multiple server workloads onto fewer physical servers to optimize resources and reduce costs.",
    },
    {
      heading: "Storage Virtualization",
      desc1: "Offers: Streamlined storage management and scalability.",
      desc2:
        "What it is: Pooling physical storage from multiple devices into a single virtual storage device for better management and utilization.",
    },
    {
      heading: "Network Virtualization",
      desc1: "Offers: Improved network efficiency and flexibility.",
      desc2:
        "What it is: Creating a virtual network overlay on existing physical networks to optimize and manage network resources effectively.",
    },
    {
      heading: "Virtual Infrastructure Automation",
      desc1: "Offers: Automated IT infrastructure management.",
      desc2:
        "What it is: Deploying virtualization platforms to automate the management of IT infrastructure, reducing manual intervention and improving efficiency.",
    },
    {
      heading: "Disaster Recovery Solutions",
      desc1: "Offers: Enhanced business continuity and data protection.",
      desc2:
        "What it is: Implementing virtualization-based disaster recovery solutions to ensure data integrity and quick recovery from disruptions.",
    },
  ],
};

export const CloudServicesData = {
  HeroImage: CloudBg,
  HeroHeading: "Cloud Managed Services",
  HeroSubheading:
    "Unleash Availability, Speed, Security, and Scalability with Our Cloud Solutions.",
  CatchyHeading:
    "Embrace the Cloud: It’s No Longer a Choice, But a Necessity for Modern Enterprises.",
  CatchySubheading:
    "Our comprehensive cloud managed services empower you to scale and manage your workloads seamlessly across private, public, and multi-cloud environments.",
  WhatIsImage: CloudImage,
  Question: "What are Cloud Managed Services?",
  Answer:
    "Cloud managed services involve the comprehensive management of cloud infrastructure and applications, providing businesses with the flexibility to scale resources as needed. Enterprises today demand cloud solutions that are scalable on-demand, enabling them to ramp up, scale down, or modify their cloud server, storage, and network resources anytime, anywhere. SAS Consulting Services offer an end-to-end quality cloud managed service, taking full responsibility for consulting, designing, building, optimizing, and managing your private cloud, public cloud, and multi-cloud solutions such as Google Cloud Platform, AWS, and Microsoft Azure. We help you scale and migrate your application workloads to the cloud seamlessly, giving you the autonomy to resize cloud infrastructure on the go.",
  Solutions: [
    {
      heading: "Cloud Consulting",
      desc1: "Offers: Expert guidance on cloud strategy and implementation.",
      desc2:
        "What it is: Assessing your business needs and designing a tailored cloud strategy for optimal performance and cost-efficiency.",
    },
    {
      heading: "Cloud Architecture Design",
      desc1: "Offers: Customized cloud architecture tailored to your needs.",
      desc2:
        "What it is: Designing a robust cloud infrastructure that aligns with your business objectives and technical requirements.",
    },
    {
      heading: "Cloud Migration Services",
      desc1: "Offers: Seamless transition of workloads to the cloud.",
      desc2:
        "What it is: Assisting in migrating applications and data to the cloud with minimal disruption to business operations.",
    },
    {
      heading: "Multi-Cloud Management",
      desc1: "Offers: Unified management across different cloud platforms.",
      desc2:
        "What it is: Managing resources across multiple cloud providers to enhance flexibility and prevent vendor lock-in.",
    },
    {
      heading: "Cloud Optimization",
      desc1: "Offers: Enhanced performance and cost savings.",
      desc2:
        "What it is: Continuously monitoring and optimizing cloud resources to improve efficiency and reduce costs.",
    },
    {
      heading: "Security and Compliance Management",
      desc1: "Offers: Comprehensive security solutions for cloud environments.",
      desc2:
        "What it is: Implementing security measures and ensuring compliance with industry regulations for your cloud infrastructure.",
    },
  ],
};
