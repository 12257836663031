import lock from "../assets/home_images/services_lock.png";
import shield from "../assets/home_images/services_shield.png";
import security from "../assets/home_images/security_icon_3d.png";
import server from "../assets/home_images/server.png";
import board from "../assets/home_images/board.png";
import ShieldLock from "../assets/home_images/shieldlock.png";
import Globe from "../assets/home_images/globe.png";
import teamWork from "../assets/home_images/teamWork.png";
import Support from "../assets/home_images/support.png";
import Laptop from "../assets/home_images/laptop.png";

export const Solutions = [
  {
    name: "Network Infrastructure",
    url: "/networkinfrastructuresolutions",
  },
  {
    name: "Security",
    url: "/securitysolutions",
  },
  {
    name: "Data center",
    url: "/datacentersolutions",
  },
  {
    name: "Virtualization",
    url: "/virtualizationsolutions",
  },
  {
    name: "Cloud",
    url: "/cloudsolutions",
  },
];

export const Services = [
  {
    name: "Compliance and regulatory consulting",
    url: "/complianceandregulartoryconsulting",
  },
  {
    name: "Governance, Risk, and Compliance",
    url: "/governanceriskandcompliance",
  },
  {
    name: "GDPR Compliance Services",
    url: "/gdprcompliance",
  },
  {
    name: "Security information event management (SIEM)",
    url: "/siem",
  },
  {
    name: "Security-architecture Review & Assessment",
    url: "/securityarchitecturereveiwassessment",
  },
  {
    name: "VCISO services",
    url: "/vciso",
  },
  {
    name: "PCI DSS",
    url: "/pcidss",
  },
  {
    name: "Vulnerability Assessment and Penetration Testing (VAPT)",
    url: "/vapt",
  },
  {
    name: "ISO 27001 consulting",
    url: "/iso27001consulting",
  },
  {
    name: "Support & Maintenance",
    url: "/supportmaintenance",
  },
];

export const CraftedSolutions = [
  {
    heading: "Expertise",
    content:
      "Bringing deep industry knowledge and specialized skills to tackle every cybersecurity challenge.",
  },
  {
    heading: "Adaptability",
    content:
      "A team of experts ready to swiftly adapt to new threats and evolving technologies, ensuring your security stays ahead of the curve.",
  },
  {
    heading: "Empowerment",
    content:
      "Equipping clients with the essential tools and knowledge to effectively protect their digital assets.",
  },
];

export const CybersecurityServices = [
  {
    name: "Compliance and regulatory consulting",
    url: "/complianceandregulartoryconsulting",
    image: lock,
  },
  {
    name: "Governance, Risk, and Compliance",
    url: "/governanceriskandcompliance",
    image: security,
  },
  {
    name: "GDPR Compliance Services",
    url: "/gdprcompliance",
    image: shield,
  },
  {
    name: "Security information event management (SIEM)",
    url: "/siem",
    image: server,
  },
  {
    name: "Security-architecture Review & Assessment",
    url: "/securityarchitecturereveiwassessment",
    image: board,
  },
  {
    name: "VCISO services",
    url: "/vciso",
    image: ShieldLock,
  },
  {
    name: "Vulnerability Assessment and Penetration Testing (VAPT)",
    url: "/vapt",
    image: Globe,
  },
  {
    name: "PCI DSS",
    url: "/pcidss",
    image: Laptop,
  },
  {
    name: "ISO 27001 consulting services",
    url: "/iso27001consulting",
    image: teamWork,
  },
  {
    name: "Support & Maintenance",
    url: "/supportmaintenance",
    image: Support,
  },
];

export const MissionData = [
  {
    heading: "Streamline Operations",
    desc: "Optimizing business processes for maximum efficiency.",
  },
  {
    heading: "Enhance Security",
    desc: "Safeguarding your digital assets with advanced protection measures.",
  },
  {
    heading: "Boost Resilience",
    desc: "Strengthening your organization to withstand and recover from threats.",
  },
];
