import React from "react";
import { IoIosCloseCircle } from "react-icons/io";

const TermsModal = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 w-full min-h-screen bg-black/80"
      onClick={onClose}
    >
      <div
        className="gap-3 bg-white rounded-md p-5 flex flex-col z-[100] md:w-[50vw] md:h-[50vh] w-[90vw] h-[70vh] overflow-y-scroll relative text-gray-500"
        onClick={(e) => e.stopPropagation()}
      >
        <IoIosCloseCircle
          size={30}
          onClick={onClose}
          className="absolute top-2 right-2 cursor-pointer hover:scale-105 transition duration-300 ease-linear"
        />
        <h1 className="gradient-text-1 font-bold text-xl md:text-2xl xl:text-3xl pb-5">
          Terms and conditions
        </h1>
        {/* Intro section */}
        <h2 className="font-semibold text-black">Introduction</h2>
        <p>
          Welcome to IonArc Consultancy's website. By accessing and using our
          website, you agree to comply with and be bound by the following terms
          and conditions. Please review them carefully. If you do not agree with
          any part of these terms and conditions, you must not use our website.
        </p>
        {/* Intro section */}
        {/* services section */}
        <h2 className="font-semibold text-black">Services</h2>
        <p className="text-gray-500">
          IonArc Consultancy provides cybersecurity services and solutions for
          businesses. These services include, but are not limited to, risk
          assessments, security audits, incident response, and ongoing security
          monitoring.
        </p>
        {/* services section */}
        {/* use of website section */}
        <h2 className="font-semibold text-black">Use of the website</h2>
        <ul>
          <li>
            <span className="font-medium">Eligibility:</span>
            You must be at least 18 years old to use our website and services.
          </li>
          <li>
            <span className="font-medium">User Conduct:</span>
            You agree not to use the website for any unlawful purpose or any
            purpose prohibited under this clause. You agree not to use the
            website in any way that could damage the website, the services, or
            the general business of IonArc Consultancy.
          </li>
          <li>
            <span className="font-medium">Account Security: </span>
            If you create an account on our website, you are responsible for
            maintaining the security of your account and you are fully
            responsible for all activities that occur under the account.
          </li>
        </ul>
        {/* use of website section */}
        {/* Intellectual Property section */}
        <h2 className="font-semibold text-black">Intellectual Property</h2>
        <p>
          All content on the website, including text, graphics, logos, and
          software, is the property of IonArc Consultancy and is protected by
          intellectual property laws. Unauthorized use of the content may
          violate copyright, trademark, and other laws.
        </p>
        {/* Intellectual Property section*/}
        {/* Data Handling and Privacy section */}
        <h2 className="font-semibold text-black">Data Handling and Privacy</h2>
        <ul>
          <li>
            <span className="font-medium">Data Collection:</span>
            We collect personal data that you provide to us directly, such as
            when you register for an account, request a service, or communicate
            with us. This data may include your name, email address, phone
            number, and business details.
          </li>
          <li>
            <span className="font-medium">Use of Data: </span>
            We use your data to provide and improve our services, to communicate
            with you, and to comply with legal obligations. We may also use your
            data for marketing purposes, but only with your explicit consent.
          </li>
          <li>
            <span className="font-medium"> Data Security: </span>
            We implement a variety of security measures to ensure the safety of
            your personal data. However, no method of transmission over the
            internet or method of electronic storage is 100% secure, so we
            cannot guarantee absolute security.
          </li>
          <li>
            <span className="font-medium"> Third-Party Sharing: </span>
            We do not sell, trade, or otherwise transfer to outside parties your
            Personally Identifiable Information unless we provide users with
            advance notice. This does not include website hosting partners and
            other parties who assist us in operating our website, conducting our
            business, or serving our users, so long as those parties agree to
            keep this information confidential.
          </li>
        </ul>
        {/* Data Handling and Privacy section */}
        {/* Limitation of Liability section */}
        <h2 className="font-semibold text-black">Limitation of Liability</h2>
        <p>
          IonArc Consultancy shall not be liable for any direct, indirect,
          incidental, special, or consequential damages resulting from the use
          or inability to use the website or services, even if IonArc
          Consultancy has been advised of the possibility of such damages. This
          includes, but is not limited to, damages for loss of profits, use,
          data, or other intangible losses.
        </p>
        {/* Limitation of Liability section */}
        {/* Indemnification section */}
        <h2 className="font-semibold text-black">Indemnification</h2>
        <p>
          You agree to indemnify and hold IonArc Consultancy, its affiliates,
          and their respective officers, directors, agents, and employees,
          harmless from any claim or demand, including reasonable attorneys'
          fees, made by any third party due to or arising out of your use of the
          website, your violation of these terms and conditions, or your
          violation of any rights of another.
        </p>
        {/* Indemnification section */}
        {/* Changes to Terms and Conditions section */}
        <h2 className="font-semibold text-black">
          Changes to Terms and Conditions
        </h2>
        <p>
          IonArc Consultancy reserves the right to change these terms and
          conditions at any time. Any changes will be posted on this page. Your
          continued use of the website after such changes have been made
          constitutes your acceptance of the new terms and conditions.
        </p>
        {/* Changes to Terms and Conditions section */}
        {/* Governing Law section */}
        <h2 className="font-semibold text-black">Governing Law</h2>
        <p>
          These terms and conditions are governed by and construed in accordance
          with the laws of [Your Country/State], and you irrevocably submit to
          the exclusive jurisdiction of the courts in that State or location.
        </p>
        {/* Governing Law section */}
      </div>
    </div>
  );
};

export default TermsModal;
