import React from "react";

const LoadingSpinner = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="spinner"></div>
      <style jsx>{`
        .spinner {
          border: 4px solid #f3f3f3;
          border-top: 4px solid #1d2671;
          border-right: 4px solid #34e89e;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          animation: spin 1s linear infinite;
        }
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  );
};

export default LoadingSpinner;
