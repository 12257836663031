import React from "react";
import { IoIosCloseCircle } from "react-icons/io";

const PrivacyModal = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 w-full min-h-screen bg-black/80"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-md p-5 flex flex-col z-[100] md:w-[50vw] md:h-[50vh] w-[90vw] h-[70vh] overflow-y-scroll relative gap-3
        text-gray-500"
        onClick={(e) => e.stopPropagation()}
      >
        <IoIosCloseCircle
          size={30}
          onClick={onClose}
          className="absolute top-2 right-2 cursor-pointer hover:scale-105 transition duration-300 ease-linear"
        />
        <h1 className="gradient-text-1 font-bold text-xl md:text-2xl xl:text-3xl pb-5">
          Privacy Policy
        </h1>
        {/* introduction */}
        <h2 className="font-medium text-black">Introduction:</h2>
        <p>
          IonArc Consultancy is committed to protecting your privacy. This
          Privacy Policy explains how we collect, use, disclose, and safeguard
          your information when you visit our website and use our services.
          Please read this policy carefully. If you do not agree with the terms
          of this privacy policy, please do not access the site.
        </p>
        {/* introduction */}
        {/* Info we collect section */}
        <h2 className="font-medium text-black">Information We Collect</h2>
        <p>
          We may collect information about you in a variety of ways. The
          information we may collect on the Site includes:
        </p>
        <ul>
          <li>
            <span className="font-medium text-gray-700">Personal Data:</span>{" "}
            Personally identifiable information, such as your name, shipping
            address, email address, and telephone number, and demographic
            information, such as your age, gender, hometown, and interests, that
            you voluntarily give to us when you register with the Site or when
            you choose to participate in various activities related to the Site,
            such as online chat and message boards.
          </li>
          <li>
            <span className="font-medium text-gray-700">Derivative Data:</span>{" "}
            Information our servers automatically collect when you access the
            Site, such as your IP address, your browser type, your operating
            system, your access times, and the pages you have viewed directly
            before and after accessing the Site.
          </li>
          <li>
            <span className="font-medium text-gray-700">Financial Data: </span>
            Financial information, such as data related to your payment method
            (e.g., valid credit card number, card brand, expiration date) that
            we may collect when you purchase, order, return, exchange, or
            request information about our services from the Site.
          </li>
          <li>
            <span className="font-medium text-gray-700">
              Data from Social Networks:
            </span>{" "}
            User information from social networking sites, such as Facebook,
            Google+, Instagram, Pinterest, Twitter, including your name, your
            social network username, location, gender, birth date, email
            address, profile picture, and public data for contacts, if you
            connect your account to such social networks.
          </li>
        </ul>
        {/* Info we collect section */}
        {/* use of info section */}
        <h2 className="font-medium text-black">Use of Your Information</h2>
        <p>
          Having accurate information about you permits us to provide you with a
          smooth, efficient, and customized experience. Specifically, we may use
          information collected about you via the Site to:
        </p>
        <ul className="font-medium">
          <li>Create and manage your account.</li>
          <li>
            Process your transactions and send you related information,
            including purchase confirmations and invoices.
          </li>
          <li>
            Send you technical notices, updates, security alerts, and support
            and administrative messages.
          </li>
          <li>
            Respond to your comments, questions, and requests and provide
            customer service.
          </li>
          <li>
            Communicate with you about services, offers, promotions, rewards,
            and events offered by IonArc Consultancy and others, and provide
            news and information we think will be of interest to you.
          </li>
          <li>
            Monitor and analyze trends, usage, and activities in connection with
            our services.
          </li>
          <li>
            Detect, investigate and prevent fraudulent transactions and other
            illegal activities and protect the rights and property of IonArc
            Consultancy and others.
          </li>
          <li>
            Personalize and improve the services and provide advertisements,
            content or features that match user profiles or interests
          </li>
        </ul>
        {/* use of info section */}
        {/* Disclosure of info */}
        <h2 className="font-medium text-black">
          Disclosure of Your Information
        </h2>
        <p>
          We may share information we have collected about you in certain
          situations. Your information may be disclosed as follows:
        </p>
        <ul>
          <li>
            <span className="font-medium text-gray-700">
              By Law or to Protect Rights:
            </span>{" "}
            If we believe the release of information about you is necessary to
            respond to legal process, to investigate or remedy potential
            violations of our policies, or to protect the rights, property, and
            safety of others, we may share your information as permitted or
            required by any applicable law, rule, or regulation
          </li>
          <li>
            <span className="font-medium text-gray-700">
              Third-Party Service Providers:
            </span>{" "}
            We may share your information with third parties that perform
            services for us or on our behalf, including payment processing, data
            analysis, email delivery, hosting services, customer service, and
            marketing assistance.
          </li>
          <li>
            <span className="font-medium text-gray-700">
              Marketing Communications:{" "}
            </span>
            With your consent, or with an opportunity for you to withdraw
            consent, we may share your information with third parties for
            marketing purposes, as permitted by law.
          </li>
          <li>
            <span className="font-medium text-gray-700">
              Business Transfers:
            </span>{" "}
            We may share or transfer your information in connection with, or
            during negotiations of, any merger, sale of company assets,
            financing, or acquisition of all or a portion of our business to
            another company.
          </li>
          <li>
            <span className="font-medium text-gray-700">Affiliates:</span> We
            may share your information with our affiliates, in which case we
            will require those affiliates to honor this Privacy Policy.
            Affiliates include our parent company and any subsidiaries, joint
            venture partners or other companies that we control or that are
            under common control with us.
          </li>
        </ul>
        {/* Disclosure of info */}
        {/* Data Security section */}
        <h2 className="font-medium text-black">Data Security</h2>
        <p>
          We use administrative, technical, and physical security measures to
          help protect your personal information. While we have taken reasonable
          steps to secure the personal information you provide to us, please be
          aware that despite our efforts, no security measures are perfect or
          impenetrable, and no method of data transmission can be guaranteed
          against any interception or other types of misuse
        </p>
        {/* Data Security section */}
        {/* Your Rights */}
        <h2 className="font-medium text-black">Your Rights</h2>
        <p>
          Depending on your location, you may have the following rights
          regarding your personal information:
        </p>
        <ul className="font-medium">
          <li>
            Access: You may have the right to request a copy of the personal
            information we hold about you.
          </li>
          <li>
            Correction: You may have the right to request that we correct any
            inaccuracies in your personal information.
          </li>
          <li>
            Deletion: You may have the right to request that we delete your
            personal information.
          </li>
          <li>
            Objection: You may have the right to object to the processing of
            your personal information.
          </li>
          <li>
            Restriction: You may have the right to request that we restrict the
            processing of your personal information.
          </li>
          <li>
            Portability: You may have the right to request that we provide your
            personal information to you in a structured, commonly used, and
            machine-readable format, or that we transmit it directly to another
            data controller.
          </li>
        </ul>
        {/* Your Rights */}
        {/* Changes to This Privacy Policy */}
        <h2 className="font-medium text-black">
          Changes to This Privacy Policy
        </h2>
        <p>
          IonArc Consultancy reserves the right to change this Privacy Policy at
          any time. We will notify you of significant changes to our Privacy
          Policy by placing a prominent notice on our site. Your continued use
          of the site after such changes have been made constitutes your
          acceptance of the new Privacy Policy.
        </p>
        {/* Changes to This Privacy Policy */}
      </div>
    </div>
  );
};

export default PrivacyModal;
