import React from "react";
import { Services, Solutions } from "../data/index";
import logo from "../assets/Ionarc_logo.jpg";
import { Link } from "react-router-dom";

const splitArray = (array) => {
  const half = Math.ceil(array.length / 2);
  return [array.slice(0, half), array.slice(half)];
};

const Footer = ({ onTermsOpenModal, onPrivacyOpenModal }) => {
  const [firstHalf, secondHalf] = splitArray(Services);

  return (
    <footer className="flex flex-col w-full rounded-b-md">
      {/* footer links */}
      <div className="grid grid-cols-1 gap-7 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  px-5 sm:px-8 md:px-12 lg:px-20 mb-5">
        <div className="flex flex-col gap-3">
          <h3 className="font-bold text-[#1D2671]">Solutions</h3>
          {Solutions.map((soln, index) => (
            <Link className="flex" to={`${soln.url}`} key={index}>
              {soln.name}
            </Link>
          ))}
        </div>
        <div className="flex flex-col gap-3 mt-7 sm:mt-0">
          <h3 className="font-bold text-[#1D2671]">Services</h3>
          {firstHalf.map((soln, index) => (
            <Link className="flex" to={`${soln.url}`} key={index}>
              {soln.name}
            </Link>
          ))}
        </div>
        <div className="flex flex-col gap-3">
          <h3 className="font-bold text-[#1D2671] sm:flex hidden">Services</h3>
          {secondHalf.map((soln, index) => (
            <Link className="flex" to={`${soln.url}`} key={index}>
              {soln.name}
            </Link>
          ))}
        </div>
        <div className="flex flex-col gap-3 mt-7 sm:mt-0 font-bold text-[#1D2671]">
          <Link to="/about">About us</Link>
          <p className="text-gray-600 font-medium">
            Any queries for us, contact us here
          </p>
          <a href="mailto:sales@ionarc.in">sales@ionarc.in</a>
          <a href="mailto:sales@ionarc.in">support@ionarc.in</a>
          <a href="mailto:sales@ionarc.in">enquiry@ionarc.in</a>
        </div>
      </div>
      {/* logo, privacy and terms */}
      <div className="flex flex-col md:flex-row gap-2 md:gap-5 md:items-center px-5 sm:px-8 md:px-12 lg:px-24 mb-5">
        <Link to={"/"}>
          <img
            src={logo}
            alt="logo"
            style={{ objectFit: "contain" }}
            className="w-[220px] h-[80px] sm:w-[250px] sm:h-[100px]"
          />
        </Link>
        <button
          className="border-none bg-transparent"
          onClick={onPrivacyOpenModal}
        >
          Privacy Policy
        </button>
        <button
          className="border-none bg-transparent"
          onClick={onTermsOpenModal}
        >
          Terms & conditions
        </button>
      </div>
      <div className="flex flex-col gap-2 py-2 w-full h-[80px] sm:h-[60px] bg-gradient-to-r from-white via-[#34E89E]/80 to-[#1D2671] justify-center items-center text-gray-900 font-medium text-sm">
        <p className="font-light text-gray-600">
          Click on the below link to developer's portfolio
        </p>
        <a href="https://adnan-portfolio-v1.vercel.app">
          @Designed & developed by Adnan Shaik
        </a>
      </div>
    </footer>
  );
};

export default Footer;
