//compliance imports
import ComplianceBg from "../assets/service_images/compliance/compliance.jpeg";
import ImageOne from "../assets/service_images/compliance/advisory.jpg";
import ImageTwo from "../assets/service_images/compliance/services.jpg";
import ImageThree from "../assets/service_images/compliance/improvement.jpg";
import WhatImage from "../assets/service_images/compliance/laptop.png";

//grc imports
import GRCBg from "../assets/service_images/grc/hero_image_2.jpg";
import GRCImage from "../assets/service_images/grc/hero_image_1.jpg";
import GRCAImageOne from "../assets/service_images/grc/goverance.jpg";
import GRCAImageTwo from "../assets/service_images/grc/solutions.jpg";
import GRCAImageThree from "../assets/service_images/grc/riskmanagement.jpg";

//pcidss imports
import PCIDSSBg from "../assets/service_images/pcidss/hero_image.jpg";
import PCIDSSImage from "../assets/service_images/pcidss/WhatIsImage.jpg";
import PCIDSSImageOne from "../assets/service_images/pcidss/security_approach.jpg";
import PCIDSSImageTwo from "../assets/service_images/pcidss/compliance_tracking.jpg";
import PCIDSSImageThree from "../assets/service_images/pcidss/training_program.jpg";

//ISO imports
import ISO27001Bg from "../assets/service_images/iso/hero_image.jpg";
import ISO27001Image from "../assets/service_images/iso/WhatIsImage.jpg";
import ISO27001ImageOne from "../assets/service_images/iso/solutions.jpg";
import ISO27001ImageTwo from "../assets/service_images/iso/track_record.jpg";
import ISO27001ImageThree from "../assets/service_images/iso/future_proof.jpg";

//gdpr imports
import GDPRBg from "../assets/service_images/gdpr/hero_image.jpg";
import GDPRImage from "../assets/service_images/gdpr/WhatIs.jpg";
import GDPRImageOne from "../assets/service_images/gdpr/end_to_end.jpg";
import GDPRImageTwo from "../assets/service_images/gdpr/technologies.jpg";
import GDPRImageThree from "../assets/service_images/gdpr/guidance.jpg";

//visco imports
import vCISOBg from "../assets/service_images/visco/hero_image.jpg";
import vCISOImage from "../assets/service_images/visco/WhatIs.jpg";
import vCISOImageOne from "../assets/service_images/visco/cost_effective.jpg";
import vCISOImageTwo from "../assets/service_images/visco/solutions.jpg";
import vCISOImageThree from "../assets/service_images/visco/leadership.jpg";

//security architecture imports
import SecurityArchitectureBg from "../assets/service_images/security_architecture/hero_image.jpg";
import SecurityArchitectureImage from "../assets/service_images/security_architecture/WhatIs.jpg";
import SecurityArchitectureImageOne from "../assets/service_images/security_architecture/risk_assessment.jpg";
import SecurityArchitectureImageTwo from "../assets/service_images/security_architecture/solutions.jpg";
import SecurityArchitectureImageThree from "../assets/service_images/security_architecture/risk_assessment.jpg";

//siem imports
import SIEMBg from "../assets/service_images/siem/hero_image.jpg";
import SIEMImage from "../assets/service_images/siem/WhatIs.jpg";
import SIEMImageOne from "../assets/service_images/siem/security_visibility.jpg";
import SIEMImageTwo from "../assets/service_images/siem/threat_intelligence.jpg";
import SIEMImageThree from "../assets/service_images/siem/incident_response.jpg";

//vapt imports
import VAPTBg from "../assets/service_images/vapt/hero_image.jpg";
import VAPTImage from "../assets/service_images/vapt/WhatIs.jpg";
import VAPTImageTwo from "../assets/service_images/vapt/roadmap.jpg";
import VAPTImageThree from "../assets/service_images/vapt/testing.jpg";

//support imports
import SupportMaintenanceBg from "../assets/service_images/support/hero_image.jpg";
import SupportMaintenanceImage from "../assets/service_images/support/WhatIs.jpg";
import SupportMaintenanceImageOne from "../assets/service_images/support/plans.jpg";
import SupportMaintenanceImageTwo from "../assets/service_images/support/expert_team.jpg";
import SupportMaintenanceImageThree from "../assets/service_images/support/maintainance.jpg";

export const ComplianceData = {
  BgImage: ComplianceBg,
  HeroHeading: "Compliance and Regulatory Consulting",
  HeroSubheading:
    "Simplify Security Compliance and Technical Security Complexities with IonArc",
  CatchyHeading:
    "Rethink Compliance: Moving Beyond Static Guidelines to Tackle Evolving Cyber Threats and Regulatory Demands",
  CatchySubheading:
    "From Risk to Resilience: Our holistic security services transcend mere compliance, providing a dynamic defense strategy that evolves with emerging threats and stringent regulations.",
  CatchyLine: "Protect the future of your business.",
  Question: "What is Compliance and Regulatory Consulting?",
  Answer:
    "Compliance and Regulatory Consulting involves providing expert guidance and support to businesses in understanding, implementing, and maintaining compliance with applicable laws, regulations, and industry standards. These consultants specialize in assessing regulatory risks, developing compliance strategies, and assisting organizations in achieving and maintaining regulatory compliance.",
  WhatisImage: WhatImage,
  Services: [
    "Regulatory Risk Assessment",
    "Compliance Program Development",
    "Regulatory Compliance Audits",
    "Risk Management Strategies",
    "Regulatory Filings and Reporting",
    "Compliance Training and Education",
  ],
  Why: {
    one: {
      Heading: "Expert Advisory and Consulting",
      Subheading:
        "Our firm provides precise, tailored guidance with deep industry expertise, ensuring your business meets legal standards, mitigates risks, and promotes ethical practices.",
      WhyImage: ImageOne,
    },
    two: {
      Heading: "Comprehensive Services Portfolio",
      Subheading:
        "We offer a wide range of services including Regulatory Risk Assessment, Compliance Program Development, Audits, Risk Management, Regulatory Filings, and Compliance Training, addressing all your compliance needs.",
      WhyImage: ImageTwo,
    },
    three: {
      Heading: "Commitment to Continuous Improvement",
      Subheading:
        "We ensure efficient and transparent compliance management through regular assessments of governance models, policies, and processes, keeping you ahead of regulatory demands and cybersecurity threats.",
      WhyImage: ImageThree,
    },
  },
};

export const GRCData = {
  BgImage: GRCBg,
  HeroHeading: "Governance, Risk, and Compliance Consulting",
  HeroSubheading:
    "Empower Your Business with Robust GRC Strategies Tailored to Your Needs",
  CatchyHeading:
    "Navigate Complexity: Elevate Your Governance, Risk Management, and Compliance Frameworks",
  CatchySubheading:
    "From Strategic Governance to Risk Mitigation: Our GRC services provide comprehensive solutions to manage regulatory requirements, mitigate risks, and uphold ethical standards.",
  CatchyLine: "Strengthen the backbone of your business.",
  Question: "What is Governance, Risk, and Compliance Consulting?",
  Answer:
    "Governance, Risk, and Compliance (GRC) Consulting involves providing expert advice and solutions to organizations for effective management of governance structures, risk management processes, and compliance with regulations. These consultants help develop governance frameworks, assess and mitigate risks, and ensure adherence to regulatory standards and ethical practices.",
  WhatisImage: GRCImage,
  Services: [
    "Development of governance frameworks and policies",
    "Board and executive governance support",
    "Corporate governance assessments and enhancements",
    "Risk assessments and audits",
    "Risk mitigation strategies and action plans",
    "Business continuity and disaster recovery planning",
    "Regulatory compliance assessments and gap analysis",
    "Compliance program development and implementation",
    "Compliance monitoring, reporting, and training",
  ],
  Why: {
    one: {
      Heading: "Expert Governance and Risk Advisory",
      Subheading:
        "Our firm provides specialized guidance to help your organization develop robust governance frameworks, effectively manage risks, and ensure compliance with all regulatory requirements.",
      WhyImage: GRCAImageOne,
    },
    two: {
      Heading: "Comprehensive GRC Solutions",
      Subheading:
        "We offer a wide range of GRC services including governance support, risk assessments, compliance program development, and disaster recovery planning, addressing all your organizational needs.",
      WhyImage: GRCAImageTwo,
    },
    three: {
      Heading: "Innovative Risk Management Techniques",
      Subheading:
        "We leverage advanced technologies and methodologies to continuously enhance your risk management strategies, ensuring your organization stays resilient in the face of evolving threats and challenges.",
      WhyImage: GRCAImageThree,
    },
  },
};

export const PCIDSSData = {
  BgImage: PCIDSSBg,
  HeroHeading: "PCI DSS Compliance Consulting",
  HeroSubheading:
    "Ensure Secure Payment Card Transactions with Comprehensive PCI DSS Compliance Services",
  CatchyHeading: "Secure Transactions: Achieve and Maintain PCI DSS Compliance",
  CatchySubheading:
    "From Gap Analysis to Security Controls Implementation: Our PCI DSS services ensure your business meets the stringent standards required to protect cardholder data and prevent fraud.",
  CatchyLine: "Safeguard your customers' trust.",
  Question: "What is PCI DSS Compliance?",
  Answer:
    "PCI DSS (Payment Card Industry Data Security Standard) is a framework established by major credit card companies, including Visa, Mastercard, American Express, Discover, and JCB, to secure payment card transactions. It is a set of security standards designed to protect cardholder data and prevent fraud in payment card transactions. Compliance with PCI DSS is essential for businesses that handle payment card information to ensure secure processing, storage, and transmission of cardholder data.",
  WhatisImage: PCIDSSImage,
  Services: [
    "Gap Analysis and Readiness Assessment",
    "Policy and Procedure Development",
    "Security Controls Implementation",
    "Security Awareness Training",
  ],
  Why: {
    one: {
      Heading: "Integrated Security Approach",
      Subheading:
        "We take an integrated approach to security, ensuring that PCI DSS compliance is seamlessly woven into your overall security strategy, enhancing your organization's resilience against threats.",
      WhyImage: PCIDSSImageOne,
    },
    two: {
      Heading: "Real-Time Compliance Tracking",
      Subheading:
        "Our advanced compliance tracking tools provide real-time insights into your PCI DSS status, allowing you to identify and address potential issues before they become significant problems.",
      WhyImage: PCIDSSImageTwo,
    },
    three: {
      Heading: "Customized Training Programs",
      Subheading:
        "We offer customized security awareness training programs that educate your staff on PCI DSS requirements and best practices, fostering a culture of security within your organization.",
      WhyImage: PCIDSSImageThree,
    },
  },
};

export const ISO27001Data = {
  BgImage: ISO27001Bg,
  HeroHeading: "ISO 27001 Compliance Consulting",
  HeroSubheading:
    "Achieve Information Security Excellence with Comprehensive ISO 27001 Consulting Services",
  CatchyHeading:
    "Secure Your Information: Implement and Maintain ISO 27001 Standards",
  CatchySubheading:
    "From Gap Analysis to Security Controls Implementation: Our ISO 27001 services ensure your organization meets the highest standards for information security management.",
  CatchyLine: "Protect your sensitive information with confidence.",
  Question: "What is ISO 27001?",
  Answer:
    "ISO/IEC 27001 is an internationally recognized standard for Information Security Management Systems (ISMS), providing a systematic approach to managing and protecting sensitive information. Achieving ISO 27001 certification demonstrates an organization's commitment to information security excellence.",
  WhatisImage: ISO27001Image,
  Services: [
    "Gap Analysis and Readiness Assessment",
    "ISMS Implementation and Documentation",
    "Risk Assessment and Management",
    "Security Controls Implementation",
    "Security Awareness Training",
  ],
  Why: {
    one: {
      Heading: "Customized ISMS Solutions",
      Subheading:
        "We develop tailored ISMS solutions that fit the unique needs and context of your organization, ensuring effective and efficient implementation of ISO 27001 standards.",
      WhyImage: ISO27001ImageOne,
    },
    two: {
      Heading: "Proven Track Record",
      Subheading:
        "With a proven track record of successfully guiding organizations to ISO 27001 certification, our expertise ensures a smooth and effective compliance journey.",
      WhyImage: ISO27001ImageTwo,
    },
    three: {
      Heading: "Future-Proof Security",
      Subheading:
        "We help you build a future-proof security posture by anticipating and addressing evolving threats, ensuring your ISMS remains relevant and robust in the face of new challenges.",
      WhyImage: ISO27001ImageThree,
    },
  },
};

export const GDPRData = {
  BgImage: GDPRBg,
  HeroHeading: "GDPR Compliance Consulting",
  HeroSubheading:
    "Ensure Privacy and Accountability with Comprehensive GDPR Compliance Services",
  CatchyHeading: "Protect Personal Data: Achieve and Maintain GDPR Compliance",
  CatchySubheading:
    "From Data Protection Impact Assessments to Breach Response: Our GDPR services help your organization navigate regulatory requirements and protect personal data effectively.",
  CatchyLine: "Safeguard privacy, build trust.",
  Question: "What is GDPR Compliance?",
  Answer:
    "The General Data Protection Regulation (GDPR) is a comprehensive data protection framework aimed at safeguarding the privacy and rights of individuals within the European Union (EU) and European Economic Area (EEA). GDPR compliance is essential for organizations handling personal data to ensure transparency, accountability, and lawful processing.",
  WhatisImage: GDPRImage,
  Services: [
    "Data Protection Impact Assessments",
    "Data Mapping and Inventory",
    "Privacy Policies and Notices",
    "Data Subject Rights Management",
    "Security and Data Protection Measures",
    "Data Breach Response and Notification",
    "GDPR Training and Awareness",
  ],
  Why: {
    one: {
      Heading: "End-to-End Privacy Management",
      Subheading:
        "Our holistic approach to privacy management ensures that GDPR compliance is embedded in every aspect of your organization's operations, fostering a culture of privacy and data protection.",
      WhyImage: GDPRImageOne,
    },
    two: {
      Heading: "Innovative Compliance Technologies",
      Subheading:
        "We leverage innovative technologies to streamline GDPR compliance processes, from data mapping to breach notification, ensuring efficiency and accuracy in meeting regulatory requirements.",
      WhyImage: GDPRImageTwo,
    },
    three: {
      Heading: "Expert Regulatory Guidance",
      Subheading:
        "Our team of GDPR experts provides in-depth regulatory guidance and practical solutions, helping you navigate complex legal requirements and stay ahead of evolving data protection laws.",
      WhyImage: GDPRImageThree,
    },
  },
};

export const vCISOData = {
  BgImage: vCISOBg,
  HeroHeading: "Virtual Chief Information Security Officer (vCISO) Services",
  HeroSubheading:
    "Access Experienced Cybersecurity Leadership with Comprehensive vCISO Services",
  CatchyHeading:
    "Strengthen Your Security Posture: Strategic Cybersecurity Leadership On-Demand",
  CatchySubheading:
    "From Cybersecurity Strategy to Incident Response: Our vCISO services provide expert guidance to navigate complex cybersecurity challenges and enhance your security posture.",
  CatchyLine: "Secure your business with strategic oversight.",
  Question: "What is a vCISO?",
  Answer:
    "A Virtual Chief Information Security Officer (vCISO) is an experienced cybersecurity leader who provides strategic guidance and oversight without the need for a full-time executive. vCISOs play a crucial role in developing, implementing, and managing cybersecurity strategies, policies, and programs.",
  WhatisImage: vCISOImage,
  Services: [
    "Cybersecurity Strategy and Planning",
    "Risk Management and Compliance",
    "Incident Response and Threat Management",
    "Security Program Development",
    "Vendor and Third-Party Risk Management",
    "Security Awareness and Training",
  ],
  Why: {
    one: {
      Heading: "Cost-Effective Expertise",
      Subheading:
        "Our vCISO services provide access to top-tier cybersecurity expertise at a fraction of the cost of hiring a full-time executive, allowing you to benefit from strategic leadership without the high overhead.",
      WhyImage: vCISOImageOne,
    },
    two: {
      Heading: "Adaptive Security Solutions",
      Subheading:
        "We offer adaptive security solutions tailored to your organization's specific needs, ensuring that your cybersecurity strategy evolves with emerging threats and changing business environments.",
      WhyImage: vCISOImageTwo,
    },
    three: {
      Heading: "Proven Leadership and Experience",
      Subheading:
        "Our vCISOs bring extensive experience and proven leadership in cybersecurity, helping your organization achieve a higher level of security maturity and resilience against cyber threats.",
      WhyImage: vCISOImageThree,
    },
  },
};

export const SecurityArchitectureData = {
  BgImage: SecurityArchitectureBg,
  HeroHeading: "Security-architecture Review & Assessment",
  HeroSubheading:
    "Protect Your Critical Assets with Robust Security Architecture Solutions",
  CatchyHeading:
    "Future-Proof Your Security: Tailored Architecture for Modern Enterprises",
  CatchySubheading:
    "From Network Security to Threat Detection: Our comprehensive security architecture services are designed to meet the unique needs and challenges of today's digital enterprises.",
  CatchyLine: "Secure your digital future with confidence.",
  Question: "What is Security Architecture?",
  Answer:
    "Security architecture refers to the design and structure of an organization's security controls, policies, procedures, and technologies that collectively protect against various cyber threats. It encompasses network security, data protection, access control, identity management, threat detection, and incident response capabilities.",
  WhatisImage: SecurityArchitectureImage,
  Services: [
    "Network Security Design",
    "Data Protection Strategies",
    "Access Control and Identity Management",
    "Threat Detection and Prevention",
    "Incident Response Planning",
    "Regulatory Compliance Alignment",
  ],
  Why: {
    one: {
      Heading: "Comprehensive Risk Assessment",
      Subheading:
        "We conduct thorough risk assessments to understand your organization's unique security needs, ensuring that the designed architecture effectively mitigates potential threats.",
      WhyImage: SecurityArchitectureImageOne,
    },
    two: {
      Heading: "Tailored Security Solutions",
      Subheading:
        "Our solutions are customized to align with your specific infrastructure, applications, and data assets, providing a security architecture that fits seamlessly into your operational workflows.",
      WhyImage: SecurityArchitectureImageTwo,
    },
    three: {
      Heading: "Proactive Threat Management",
      Subheading:
        "We implement proactive threat detection and prevention measures, ensuring your organization stays ahead of emerging cyber threats and maintains a robust security posture.",
      WhyImage: SecurityArchitectureImageThree,
    },
  },
};

export const SIEMData = {
  BgImage: SIEMBg,
  HeroHeading: "Security Information and Event Management (SIEM) Solutions",
  HeroSubheading:
    "Centralize Security Data and Enable Swift Incident Response with Robust SIEM Solutions",
  CatchyHeading:
    "Detect, Analyze, and Respond: Comprehensive SIEM Solutions for Modern Enterprises",
  CatchySubheading:
    "From Real-Time Threat Detection to Incident Response: Our SIEM services enhance your organization's security posture and provide actionable insights for threat mitigation.",
  CatchyLine: "Stay ahead of threats with advanced SIEM capabilities.",
  Question: "What is SIEM?",
  Answer:
    "SIEM (Security Information and Event Management) is a comprehensive cybersecurity technology that aggregates security data from various sources, such as network devices, servers, applications, and endpoints. It analyzes this data in real-time to detect security incidents, identify patterns of suspicious activity, and provide actionable insights for threat mitigation and response.",
  WhatisImage: SIEMImage,
  Services: [
    "SIEM Assessment and Planning",
    "SIEM Implementation and Integration",
    "Real-Time Threat Detection",
    "Incident Response and Investigation",
    "Security Data Analytics and Reporting",
    "Continuous Monitoring and Management",
  ],
  Why: {
    one: {
      Heading: "Holistic Security Visibility",
      Subheading:
        "Our SIEM solutions provide comprehensive visibility into your security landscape, centralizing data from diverse sources for a unified view of potential threats and incidents.",
      WhyImage: SIEMImageOne,
    },
    two: {
      Heading: "Real-Time Threat Intelligence",
      Subheading:
        "We integrate real-time threat intelligence feeds into your SIEM, ensuring you have the latest information on emerging threats and vulnerabilities to proactively defend against them.",
      WhyImage: SIEMImageTwo,
    },
    three: {
      Heading: "Expert Incident Response",
      Subheading:
        "Our team of cybersecurity experts provides swift and effective incident response, minimizing the impact of security incidents and ensuring rapid recovery and mitigation.",
      WhyImage: SIEMImageThree,
    },
  },
};

export const VAPTData = {
  BgImage: VAPTBg,
  HeroHeading:
    "Vulnerability Assessment and Penetration Testing (VAPT) Services",
  HeroSubheading:
    "Identify, Prioritize, and Remediate Security Weaknesses with Comprehensive VAPT Services",
  CatchyHeading:
    "Proactive Security Measures: Strengthen Your Defense with VAPT",
  CatchySubheading:
    "From Vulnerability Identification to Remediation: Our VAPT services provide critical insights to protect your IT infrastructure and applications from potential threats.",
  CatchyLine: "Mitigate risks and safeguard sensitive data.",
  Question: "What is VAPT?",
  Answer:
    "In today's cyber threat landscape, organizations must proactively assess and address vulnerabilities in their IT infrastructure and applications to mitigate risks and safeguard sensitive data. Vulnerability Assessment and Penetration Testing (VAPT) are essential cybersecurity practices that help identify, prioritize, and remediate security weaknesses.",
  WhatisImage: VAPTImage,
  Services: [
    "Network Vulnerability Assessment",
    "Application Penetration Testing",
    "Wireless Network Security Testing",
    "Social Engineering Assessments",
    "Cloud Security Testing",
    "Post-Remediation Verification",
  ],
  Why: {
    one: {
      Heading: "Comprehensive Risk Identification",
      Subheading:
        "Our VAPT services provide a thorough examination of your IT environment, identifying vulnerabilities across networks, applications, and cloud infrastructure to ensure no weakness is overlooked.",
      WhyImage: SecurityArchitectureImageOne,
    },
    two: {
      Heading: "Prioritized Remediation Roadmap",
      Subheading:
        "We deliver a detailed remediation roadmap, prioritizing vulnerabilities based on their risk impact, helping you address the most critical issues first to enhance your security posture effectively.",
      WhyImage: VAPTImageTwo,
    },
    three: {
      Heading: "Advanced Testing Techniques",
      Subheading:
        "Utilizing the latest penetration testing methodologies and tools, our experts simulate real-world attacks to uncover hidden vulnerabilities and provide actionable recommendations.",
      WhyImage: VAPTImageThree,
    },
  },
};

export const SupportMaintenanceData = {
  BgImage: SupportMaintenanceBg,
  HeroHeading: "Support and Maintenance Services",
  HeroSubheading:
    "Ensure Peak Performance with Comprehensive Support and Maintenance Solutions",
  CatchyHeading:
    "Reliable Support: Keep Your Computing Environment Running Smoothly",
  CatchySubheading:
    "From Telephonic to On-Site Support: Our tailored services ensure your IT infrastructure operates at optimal performance.",
  CatchyLine: "Maximize uptime, minimize disruptions.",
  Question: "What are Support and Maintenance Services?",
  Answer:
    "We provide support and maintenance services ranging from telephonic, remote, and on-site support, customized to keep your computing environment operating at peak performance.",
  WhatisImage: SupportMaintenanceImage,
  Services: [
    "24/7 Telephonic Support",
    "Remote Troubleshooting and Resolution",
    "On-Site Technical Assistance",
    "Proactive System Monitoring",
    "Software Updates and Patch Management",
    "Hardware Maintenance and Repairs",
  ],
  Why: {
    one: {
      Heading: "Customized Support Plans",
      Subheading:
        "Our support plans are tailored to meet the specific needs of your organization, ensuring that you receive the right level of assistance and maintenance for your IT environment.",
      WhyImage: SupportMaintenanceImageOne,
    },
    two: {
      Heading: "Expert Technical Team",
      Subheading:
        "Our team of experienced technicians provides reliable and efficient support, leveraging their expertise to resolve issues quickly and minimize downtime.",
      WhyImage: SupportMaintenanceImageTwo,
    },
    three: {
      Heading: "Proactive Maintenance",
      Subheading:
        "We emphasize proactive maintenance, regularly monitoring and updating your systems to prevent potential issues before they impact your operations.",
      WhyImage: SupportMaintenanceImageThree,
    },
  },
};
