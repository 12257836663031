import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import { FiMenu, FiX } from "react-icons/fi";
import logo from "../assets/ionarc_no_bg.png";
import { Solutions, Services } from "../data/index";

const Navbar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [serviceSelectValue, setServiceSelectValue] = useState("");
  const [solutionSelectValue, setSolutionSelectValue] = useState("");
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleNavigate = (e, type) => {
    const url = e.target.value;
    if (url) {
      navigate(url);
      if (type === "service") {
        setServiceSelectValue("");
      } else {
        setSolutionSelectValue("");
      }
    }
  };

  return (
    <>
      <nav className="flex w-full justify-between h-[80px] fixed top-0 z-50 px-7 md:px-14 bg-gradient-to-r from-white via-[#34E89E]/80 to-[#1D2671] rounded-t-md">
        {/* logo */}
        <div className="w-[150px] h-[75px] flex items-center justify-center">
          <RouterLink to="/">
            <img src={logo} alt="logo" style={{ objectFit: "cover" }} />
          </RouterLink>
        </div>
        {/* logo */}
        {/* nav links */}
        <ul className="hidden sm:flex flex-row gap-4 justify-center items-center text-white font-medium">
          <li>
            <select
              className="bg-inherit flex w-[125px] outline-none text-sm cursor-pointer hover:scale-105 transition ease-in-out duration-200"
              value={serviceSelectValue}
              onChange={(e) => handleNavigate(e, "service")}
            >
              <option className="text-gray-500" value="">
                Services
              </option>
              {Services.map((ser, index) => (
                <option className="text-gray-500" key={index} value={ser.url}>
                  {ser.name}
                </option>
              ))}
            </select>
          </li>
          <li>
            <select
              className="bg-inherit flex w-[125px] outline-none text-sm cursor-pointer hover:scale-105 transition ease-in-out duration-200"
              value={solutionSelectValue}
              onChange={(e) => handleNavigate(e, "solution")}
            >
              <option className="text-gray-500" value="">
                Solutions
              </option>
              {Solutions.map((sol, index) => (
                <option className="text-gray-500" key={index} value={sol.url}>
                  {sol.name}
                </option>
              ))}
            </select>
          </li>
          <li className="cursor-pointer hover:scale-105 transition ease-in-out duration-200">
            <RouterLink to="/about">About us</RouterLink>
          </li>
          <li>
            <Link
              to="getInTouch"
              smooth={true}
              duration={200}
              offset={-120}
              className="bg-transparent border-2 border-white text-white font-medium justify-center items-center px-4 py-2 rounded-lg hover:scale-105 transition ease-in-out duration-200 cursor-pointer"
              onClick={() => setSidebarOpen(false)}
            >
              Get in touch
            </Link>
          </li>
        </ul>
        {/* Hamburger menu for smaller screens */}
        <div className="sm:hidden flex items-center">
          <button className="text-white text-3xl" onClick={toggleSidebar}>
            {sidebarOpen ? <FiX /> : <FiMenu />}
          </button>
        </div>
        {/* Hamburger menu for smaller screens */}
      </nav>
      {/* Sidebar for mobiles */}
      <div
        className={`fixed top-0 right-0 h-full w-2/3 bg-[#1D2671] transform ${
          sidebarOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out z-[100]`}
      >
        <ul className="flex flex-col gap-4 p-10 text-white font-medium cursor-pointer">
          <li>
            <select
              className="bg-inherit flex w-[150px] outline-none text-sm"
              value={serviceSelectValue}
              onChange={(e) => {
                handleNavigate(e, "service");
                setSidebarOpen(false);
              }}
            >
              <option className="text-gray-500" value="">
                Services
              </option>
              {Services.map((ser, index) => (
                <option className="text-gray-500" key={index} value={ser.url}>
                  {ser.name}
                </option>
              ))}
            </select>
          </li>
          <li>
            <select
              className="bg-inherit flex w-[150px] outline-none text-sm"
              value={solutionSelectValue}
              onChange={(e) => {
                handleNavigate(e, "solution");
                setSidebarOpen(false);
              }}
            >
              <option className="text-gray-500" value="">
                Solutions
              </option>
              {Solutions.map((sol, index) => (
                <option className="text-gray-500" key={index} value={sol.url}>
                  {sol.name}
                </option>
              ))}
            </select>
          </li>
          <li>
            <RouterLink to="/about" onClick={toggleSidebar}>
              About us
            </RouterLink>
          </li>
          <li>
            <Link
              to="getInTouch"
              smooth={true}
              duration={200}
              offset={-120}
              className="bg-transparent border-2 border-white text-white font-medium justify-center items-center px-4 py-2 rounded-md cursor-pointer"
              onClick={() => setSidebarOpen(false)}
            >
              Get in touch
            </Link>
          </li>
        </ul>
      </div>
      {/* Overlay for sidebar */}
      {sidebarOpen && (
        <div
          onClick={toggleSidebar}
          className="fixed inset-0 bg-black/50 z-[60]"
        ></div>
      )}
    </>
  );
};

export default Navbar;
